import { ConsoleEngine, Logging, NgLogEngine } from '@nextgis/logging';

import pkg from '../../package.json';

const logger = new Logging({
  eachLog: (msg, opt = {}) => {
    opt.data = opt.data || {};
    opt.data.version = pkg.version;
    return opt;
  },
  engines: [
    new ConsoleEngine({ enabled: process.env.NODE_ENV === 'development' }),
    new NgLogEngine({
      clientId: 'd425362d-fa60-4591-8c99-7796729fba1a',
      nglogUrl: 'https://nglog.staging.nextgis.com',
      stopOnConnectionError: true,
      enabled: process.env.NODE_ENV !== 'development',

      // clientId: 'bbb730cc-565b-4501-bc50-c2fa94ea8886',
      // nglogUrl: 'http://localhost:8081',
    }),
  ],
});

export default logger;
