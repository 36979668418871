import { getImageAdapterOptions } from '@nextgis/ngw-kit';
import { arrayChunk } from '@nextgis/utils';

import { MAX_BASELAYER_DISPLAY, baseUrl, baselayersPrefix } from '../../config';
import connector from '../../services/connector';

import type { IdentifyLayerItem } from '../../interfaces';
import type { NgwMap } from '@nextgis/ngw-map';

interface AddBaselayersOptions {
  identifyLayers: IdentifyLayerItem[];
  ngwMap: NgwMap;
}

export function addBaselayers({
  identifyLayers,
  ngwMap,
}: AddBaselayersOptions): void {
  const layersToShow = identifyLayers.slice(0, MAX_BASELAYER_DISPLAY);
  const layersChunkToAdd = arrayChunk(layersToShow, 10);
  for (let j = 0; j < layersChunkToAdd.length; j++) {
    const c = layersChunkToAdd[j];
    const layersId = baselayersPrefix + '-' + j;
    if (!ngwMap.getLayer(layersId)) {
      const resources: number[] = [];
      for (let i = 0; i < c.length; i++) {
        resources.push(c[i].styleId);
      }
      ngwMap.addImageLayer({
        ...getImageAdapterOptions({
          headers: connector.getAuthorizationHeaders(),
          resourceId: resources,
          baseUrl,
        }),
        setViewDelay: 1000,
        id: layersId,
        order: j,
      });
    }
  }
}
