import { Typography } from 'antd';
import { useCallback, useEffect, useState } from 'react';

import { PanelText } from '../../PanelText';

import { CoordinatesInput } from './CoordinatesInput';

import type { IdentifyModeProps } from '../../../../interfaces';
import type { LngLatArray } from '@nextgis/utils';
import type { MapClickEvent } from '@nextgis/webmap';
import type { FunctionComponent } from 'react';

const { Text } = Typography;

export const PointIdentifyPanel: FunctionComponent<IdentifyModeProps> = (
  props,
) => {
  const { ngwMap, onGeom } = props;

  const [lngLat, setLngLat] = useState<LngLatArray | null>(null);

  const startIdentify = useCallback(() => {
    if (ngwMap) {
      ngwMap.setCursor('crosshair');
      ngwMap.emitter.on('click', onMapClick);
    }
  }, [ngwMap]);

  const stopIdentify = useCallback(() => {
    if (ngwMap) {
      ngwMap.setCursor('');
      ngwMap.emitter.off('click', onMapClick);
    }
    setLngLat(null);
  }, [ngwMap]);

  const onMapClick = (e: MapClickEvent) => {
    const [lng, lat] = e.lngLat;
    const toSet = [Math.abs(Number(lng)) % 360, lat];
    setLngLat(toSet);
  };

  useEffect(() => {
    if (lngLat) {
      onGeom([lngLat]);
    } else {
      onGeom(null);
    }
  }, [lngLat, onGeom]);

  useEffect(() => {
    startIdentify();

    return () => {
      stopIdentify();
    };
  }, [startIdentify, stopIdentify]);

  return (
    <>
      <div>
        <CoordinatesInput
          lngLat={lngLat}
          onChange={setLngLat}
        ></CoordinatesInput>
        <PanelText>
          <Text type="secondary" italic>
            Кликните по карте для установки координат
          </Text>
        </PanelText>
      </div>
    </>
  );
};
