import { getIcon } from '@nextgis/icons';

import { INTERSECTION_LAYER_ORDER } from '../../config';
import { featureFromCoords } from '../../utils/featureFromCoords';

import type { IdentifyType } from '../../interfaces';
import type { NgwMap } from '@nextgis/ngw-map';
import type { Paint } from '@nextgis/paint';
import type { Feature } from 'geojson';

interface DrawLayerOnMapOptions {
  identifyType: IdentifyType;
  coords: number[][] | null;
  bufferCoords: number[][] | null;
  layerId: string;
  ngwMap: NgwMap;
}

interface AddLayerByCoordsOptions {
  coords: number[][] | null;
  layerId: string;
  ngwMap: NgwMap;
  color?: string;
}

export async function drawLayerOnMap({
  identifyType,
  coords,
  bufferCoords,
  layerId,
  ngwMap,
}: DrawLayerOnMapOptions): Promise<void> {
  const bufferLayerId = layerId + '-buffer';
  ngwMap.removeLayer(layerId);
  ngwMap.removeLayer(bufferLayerId);
  let layerToZoom = layerId;
  if (bufferCoords) {
    layerToZoom = bufferLayerId;
    await _addLayerByCoords({
      ngwMap,
      coords: bufferCoords,
      layerId: bufferLayerId,
      color: 'orange',
    });
  }
  await _addLayerByCoords({ ngwMap, coords, layerId });

  if (identifyType === 'table') {
    ngwMap.fitLayer(layerToZoom, { maxZoom: 15, padding: 10 });
  }
}

async function _addLayerByCoords({
  layerId,
  coords,
  ngwMap,
  color,
}: AddLayerByCoordsOptions) {
  let data: Feature | null = null;
  if (coords) {
    data = featureFromCoords(coords);
  }
  if (data) {
    let paint: Paint = {};
    if (data.geometry.type === 'Point') {
      paint = getIcon({
        shape: 'plus',
        strokeColor: 'white',
        size: 20,
      });
    } else if (color) {
      paint.color = color;
    }

    await ngwMap.addFeatureLayer({
      id: layerId,
      data,
      paint,
      order: INTERSECTION_LAYER_ORDER,
    });
  }
}
