import { InputNumber } from 'antd';

interface IdentifySettingsOptions {
  coords: number[][] | null;
  bufferSize: number;
  setBufferSize: (val: number) => void;
}
export function IdentifySettings({
  coords,
  bufferSize,
  setBufferSize,
}: IdentifySettingsOptions) {
  if (coords) {
    return (
      <>
        <InputNumber
          style={{ width: '100%' }}
          size="small"
          addonAfter="км"
          addonBefore="Буфер"
          value={bufferSize}
          onChange={(v: number | null) => {
            typeof v === 'number' && setBufferSize(v);
          }}
        ></InputNumber>
      </>
    );
  }
  return null;
}
