import { ConfigProvider } from 'antd';
import ruRu from 'antd/locale/ru_RU';

import { PrpMap } from './components/PrpMap';
import logger from './services/logger';

export default function App() {
  logger.info('The app is started', {
    operationId: 'app-started',
  });
  return (
    <ConfigProvider locale={ruRu}>
      <PrpMap />
    </ConfigProvider>
  );
}
