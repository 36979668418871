import { AimOutlined } from '@ant-design/icons';
import { defined } from '@nextgis/utils';
import { round } from '@nextgis/utils';
import { Button, Form, InputNumber } from 'antd';
import { useEffect } from 'react';

import type { LngLatArray } from '@nextgis/utils';
import type { ValidatorRule } from 'rc-field-form/lib/interface';

interface CoordinatesInputProps {
  lngLat: LngLatArray | null;
  onChange: (lngLat: LngLatArray | null) => void;
}

export function CoordinatesInput({ lngLat, onChange }: CoordinatesInputProps) {
  const [form] = Form.useForm();

  const createRule =
    (limit: number): (() => ValidatorRule) =>
    () => ({
      validator(_, value) {
        if (Math.abs(value) > limit) {
          return Promise.reject();
        }
        return Promise.resolve();
      },
    });

  const onCoordinatesInputChange = (e: { lng: number; lat: number }) => {
    if (defined(e.lng) && defined(e.lat)) {
      onChange([e.lng, e.lat]);
    }
  };
  useEffect(() => {
    const [lng, lat] = lngLat ? lngLat.map((x) => round(x, 6)) : [];
    form.setFieldsValue({ lat, lng });
  }, [form, lngLat]);

  return (
    <Form
      onFinish={onCoordinatesInputChange}
      form={form}
      style={{
        marginBottom: '10px',
      }}
    >
      <Form.Item
        name="lat"
        style={{
          display: 'inline-block',
          width: 'calc(43% - 8px)',
          margin: '0',
        }}
        rules={[createRule(180)]}
      >
        <InputNumber
          style={{
            width: '100%',
          }}
          placeholder="Широта"
        />
      </Form.Item>
      <Form.Item
        name="lng"
        style={{
          display: 'inline-block',
          width: 'calc(43% - 8px)',
          margin: '0 0 0 8px',
        }}
        rules={[createRule(360)]}
      >
        <InputNumber
          style={{
            width: '100%',
          }}
          placeholder="Долгота"
        />
      </Form.Item>
      <Form.Item
        style={{
          display: 'inline-block',
          width: '10%',
          margin: '0 0 0 8px',
        }}
      >
        <Button htmlType="submit">
          <AimOutlined />
        </Button>
      </Form.Item>
    </Form>
  );
}
