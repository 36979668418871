import ReactNgwMap from '@nextgis/react-ngw-leaflet';
import { MapControl } from '@nextgis/react-ngw-map';
import { useState } from 'react';
import styled from 'styled-components';

import connector from '../services/connector';

import { IdentifyPanel } from './Identify/Identify';

import type { NgwMap } from '@nextgis/ngw-map';
import type { FunctionComponent } from 'react';

const IdentifyPanelWrapper = styled.div({
  background: 'white',
  padding: '1rem',
  width: '390px',
});

export const PrpMap: FunctionComponent = () => {
  const [identifyMode] = useState(true);
  const [ngwMap, setNgwMap] = useState<NgwMap | null>(null);

  return (
    <ReactNgwMap
      id="map"
      osm
      connector={connector}
      whenCreated={setNgwMap}
      bounds={[102.152, 49.315, 113.84, 56.677]}
    >
      {identifyMode && ngwMap ? (
        <MapControl position="top-right" margin={true}>
          <IdentifyPanelWrapper
            onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
              e.stopPropagation()
            }
          >
            <IdentifyPanel ngwMap={ngwMap}></IdentifyPanel>
          </IdentifyPanelWrapper>
        </MapControl>
      ) : (
        ''
      )}
    </ReactNgwMap>
  );
};
